import ChickenBreastFunNugget from '../img/products/15845-964.jpg';
import ChickenBreastNuggets from '../img/products/15846-964.jpg';
import ChickenBreastPatties from '../img/products/15847-964.jpg';
import ChickenBreastTenders from '../img/products/15773-964.jpg';

export const productsData = [
  {
    id: 0,
    path: "chicken-breast-fun-nuggets/",
    title: "Chicken Breast Fun Nuggets",
    description: "Kids will love the shape of our Chicken Breast Fun Nuggets. Enjoy the great taste of our tender Chicken Breast Nuggets in a fun shape.",
    ingredients : [
      {
        ingredient: "Tomato, Corn, and Basil Salad"
      },
      {
        ingredient: "Mixed Seasonal Fruit"
      },
      {
        ingredient: "Spicy Cabbage Slaw"
      },
      {
        ingredient: "Grilled Zucchini with Lemon"
      }
    ],
    heatingInstructions: "Heating times approximate.",
    conventionalOven: [
      {
        step: "Preheat oven to 400°F."
      },
      {
        step: "Place frozen nuggets on baking sheet."
      },
      {
        step: "Heat 10 to 12 minutes, turning nuggets over halfway through heating time."
      }
    ],
    microwaveOven: [
      {
        step: "Place frozen nuggets on microwave-safe plate."
      },
      {
        step: "Heat uncovered on HIGH: 4 nuggets for 1 minute or 12 nuggets for 2 1/2 minutes. Turn nuggets over and rotate dish halfway through heating time. Do not overheat."
      },
      {
        step: "Let stand 1 to 2 minutes before serving."
      },
    ],
    recomendations: [
      {
        recomendation : "Preferred Cooking Method."
      }
    ],
    image: ChickenBreastFunNugget,
    imageAlt: "Chicken Breast Fun Nuggets"
  },
  {
    id: 1,
    path: "chicken-breast-nuggets/",
    title: "Chicken Breast Nuggets",
    description: "Enjoy our tender Chicken Breast Nuggets. It's a \"true-blue\" family favorite, and we're sure it will be one of your favorites too!",
    ingredients : [
      {
        ingredient: "Fruit Salad"
      },
      {
        ingredient: "Ketchup for Dipping"
      },
      {
        ingredient: "Mixed Vegetables"
      },
      {
        ingredient: "Cole Slaw"
      }
    ],
    heatingInstructions: "Heating times approximate.",
    conventionalOven: [
      {
        step: "Preheat oven to 425°F."
      },
      {
        step: "Place frozen nuggets on baking sheet."
      },
      {
        step: "Heat 10 to 12 minutes, turning nuggets over halfway through heating time."
      }
    ],
    microwaveOven: [
      {
        step: "Place frozen nuggets on microwave-safe plate."
      },
      {
        step: "Heat uncovered on HIGH: 4 nuggets for 1 minute or 12 nuggets for 2 1/2 minutes. Turn nuggets over and rotate dish halfway through heating time. Do not overheat."
      },
      {
        step: "Let stand 1 to 2 minutes before serving."
      },
    ],
    recomendations: [
      {
        recomendation : "Preferred Cooking Method."
      }
    ],
    image: ChickenBreastNuggets,
    imageAlt: "Chicken Breast Nuggets"
  },
  {
    id: 2,
    path: "chicken-breast-patties/",
    title: "Chicken Breast Patties",
    description: "Our tasty Breast Patties are made with tender, juicy breast meat. Have them by themselves or use them in a sandwich.",
    ingredients : [
      {
        ingredient: "Grilled Corn with Herb Butter"
      },
      {
        ingredient: "Orzo and Cherry Tomato Salad"
      },
      {
        ingredient: "Spinach and Mushroom Salad"
      },
      {
        ingredient: "Honey-Glazed Carrots"
      }
    ],
    heatingInstructions: "Heating times approximate.",
    conventionalOven: [
      {
        step: "Preheat oven to 425°F."
      },
      {
        step: "Place frozen patties on baking sheet."
      },
      {
        step: "Heat 12 to 18 minutes, turning patties over halfway through heating time."
      }
    ],
    microwaveOven: [
      {
        step: "Place frozen patties on microwave-safe plate."
      },
      {
        step: "Heat uncovered on HIGH: 1 pattie for 1 1/2 minutes, 2 patties for 2 1/2 minutes, 3 patties for 3 minutes, 4 patties for 4 minutes. Turn patties over and rotate dish halfway through heating time. Do not overheat."
      },
      {
        step: "Let stand 1 to 2 minutes before serving."
      },
    ],
    recomendations: [
      {
        recomendation : "Preferred Cooking Method."
      }
    ],
    image: ChickenBreastPatties,
    imageAlt: "Chicken Breast Patties"
  },
  {
    id: 3,
    path: "chicken-breast-tenders/",
    title: "Chicken Breast Tenders",
    description: "Our Chicken Breast Tenders are finger-friendly, whether you're serving them as an appetizer or on the dinner plate.",
    ingredients : [
      {
        ingredient: "Grilled Asparagus"
      },
      {
        ingredient: "Scalloped Potatoes"
      },
      {
        ingredient: "Oven-Roasted Baby Potatoes"
      },
      {
        ingredient: "Okra with Tomatoes"
      }
    ],
    heatingInstructions: "Heating times approximate.",
    conventionalOven: [
      {
        step: "Preheat oven to 425°F."
      },
      {
        step: "Place frozen tenders on baking sheet."
      },
      {
        step: "Bake 10 to 12 minutes, turning tenders over halfway through heating time."
      }
    ],
    // microwaveOven: [],
    // recomendations: [],
    image: ChickenBreastTenders,
    imageAlt: "Chicken Breast Tenders"
  }
];