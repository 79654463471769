import React from "react";
import "./productDetail.scss";
import Layout from '../components/Layout/Layout';
import WaverChickenGrid from "../components/Grid/WaverChickenGrid";
import { productsData as data } from '../data/productsDetail';

const Product = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;

  return (
    <Layout
      metaTitle="Frozen Breaded Chicken for Your Family "
      metaDescription=""
      searchTitle="Products"
    >
      <WaverChickenGrid
        isProductDetail={true}
        productData={{image: data[i].image, imageAlt: data[i].imageAlt}}
      >
        <div className="productPage">
          <div className="details">
            <h1 className="title">
              {data[i].title}
            </h1>

            <div
              className=""
              dangerouslySetInnerHTML={{ __html: data[i].description }}
            />
          </div>

          <div className="ingredients">
            <b>Try Weaver® {data[i].title} with these:</b>

            <ul>
              {
                data[i].ingredients.map(({ingredient}) => (
                  <li key={ingredient}>{ingredient}</li>
                ))
              }
            </ul>
          </div>

          <div className="heating">
            <b>Heating Instructions</b> (Appliances vary):                  
            <p>{data[i].heatingInstructions}</p>
          </div>

          <div className="conventionalOven">
            <b>Conventional Oven*</b>

            <ul>
              {
                data[i].conventionalOven.map(({step}) => (
                  <li key={step}>{step}</li>
                ))
              }
            </ul>
          </div>

          {
            ('microwaveOven' in data[i]) &&
            <div className="microwaveOven">
              <b>Microwave Oven</b>

              <ul>
                {
                  data[i].microwaveOven.map(({step}) => (
                    <li key={step}>{step}</li>
                  ))
                }
              </ul>
            </div>
          }

          {
            ('recomendations' in data[i]) &&
            <div className="recomendations">
              {
                data[i].recomendations.map(({recomendation}) => (
                  <span key={recomendation}>* {recomendation}</span>
                ))
              }
            </div>
          } 
        </div>
      </WaverChickenGrid>
    </Layout>
  );
}

export default Product;
